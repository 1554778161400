<template>
  <div class="app-view watch-list-view">
    <div>
      <div class="search-symbol">
        <span class="search-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
          </svg>
        </span>
        <input
          type="text"
          class="input_search"
          id="search_input"
          @input="changeSearchText($event)"
          placeholder="Search eg: infy bse, nifty fut, nifty weekly, gold mcx"
        />
        <span class="search-btn" @click="clearSearch">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 96 960 960"
            width="55px"
          >
            <path
              d="m444.384 727.691 110.692-110.692 110.693 110.692 41.999-41.614L596.076 576l110.076-110.077-41.614-41.614-109.462 110.692-110.692-110.692-41.614 41.614L514.077 576 402.77 686.077l41.614 41.614ZM125.309 576l166.153-235.383q12-18.385 30.533-29 18.532-10.616 41.031-10.616h401.973q28.596 0 49.644 20.548 21.048 20.549 21.048 50.145v408.612q0 29.596-21.048 50.145-21.048 20.548-49.644 20.548H363.001q-22.506 0-40.997-10.971t-30.542-29.414L125.309 576Zm72.537 0 146.482 206.614q3.077 4.231 8.27 7.116 5.192 2.885 10.962 2.885h401.439q4.615 0 8.462-3.846 3.846-3.847 3.846-8.463V371.694q0-4.616-3.846-8.463-3.847-3.846-8.462-3.846H363.078q-5.77 0-10.962 2.885-5.193 2.885-8.27 7.116L197.846 576Zm579.461 0V359.385 792.615 576Z"
            />
          </svg>
        </span>
      </div>
      <div
        :style="{
          position: 'absolute',
          top: '0px',
          left: 0,
          right: 0,
          zIndex: 2,
        }"
      >
        <SearchSymbolListing
          v-if="isSearchActive"
          :current_page_no="current_page_no"
        />
      </div>
    </div>
    <div class="watch-order-list" :class="isSearchActive ? 'bg-blur' : ''">
      <ul>
        <li v-for="(stock, key) in watchList" :key="key">
          <symbol-list
            @onSymbolClick="goToStockPage(stock)"
            :key="key"
            :stock="stock"
            ltp="--"
            delta="--"
            :id="stock.id"
            :page_no="stock.page_no"
          />
        </li>
      </ul>
    </div>
    <div class="symbol-page-tab" :class="isSearchActive ? 'bg-blur' : ''">
      <span
        class="page-tab"
        v-for="(page_no, key) in 3"
        :key="key"
        @click="fetchWatchList(key)"
        :class="{ 'selected-page': key == current_page_no }"
        >{{ page_no }}</span
      >
      <span class="setting-tab">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0,0h24v24H0V0z" fill="none" />

          <path
            d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z"
          />
          <path
            d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z"
          />
          <path
            d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"
          />
        </svg>
      </span>
    </div>
    <loader-comp :isLoading="isLoading" />
  </div>
</template>

<script>
import LoaderComp from "../components/common/LoaderComp.vue";
import SymbolList from "@/components/common/SymbolList.vue";
import SearchSymbolListing from "@/components/common/SearchSymbolListing.vue";

import { mapState } from "vuex";

export default {
  name: "WatchList",
  components: {
    "loader-comp": LoaderComp,
    SearchSymbolListing,
    SymbolList,
  },
  computed: {
    ...mapState(["watchList", "searchList", "isSearchActive", "dashboard"]),
  },
  data() {
    return {
      isLoading: false,
      current_page_no: 0,
      searchText: "",
      currentSelectedStock: null,
    };
  },
  watch: {
    searchText() {
      this.searchStocks();
    },
  },
  methods: {
    changeSearchText(event) {
      this.searchText = event.target.value;
    },
    goToStockPage(stock) {
      this.$store.commit("SET_SELECTED_STOCK_TO_BUY", {
        ...stock,
        tradingSymbol: stock.trading_symbol,
        graphName: stock.name,
        securityId: stock.security_id,
        exchange: stock.exchange,
        fullName: stock.custom_symbol,
      });
      this.$router.push({
        name: "MarketDepth",
        props: {
          from: "WATCHLIST",
        },
      });
    },
    async searchStocks() {
      this.$store.commit("SET_IS_SEARCH_ACTIVE", true);

      await this.$store.dispatch("searchStocks", {
        searchText: this.searchText,
      });
      if (this.searchText === "") {
        this.clearSearch();
      }
    },
    async clearSearch() {
      this.searchText = "";
      document.querySelector("#search_input").value = "";
      await this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
    },
    async fetchWatchList(page_no) {
      this.isLoading = true;
      this.current_page_no = page_no;
      await this.$store.dispatch("fetchWatchList", { page_no: page_no });
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchWatchList(0);
    if (Object.keys(this.dashboard).length === 0) {
      await this.$store.dispatch("fetchDashboard");
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
@import "../assets/scss/watch-list.scss";

.bg-blur {
  // filter: blur(15px);
  // -webkit-filter: blur(15px);
  opacity: 0;
}
</style>
