let BASE_API = "";
if (process.env.VUE_APP_ENVIRONMENT == "PROD") {
  BASE_API = "https://support.services-money91.com";
} else if (process.env.VUE_APP_ENVIRONMENT == "DEV") {
  BASE_API = "https://odoo-test.services-money91.com";
}

let config = null;
function setConfig(value) {
  config = value;
}
export { BASE_API, config, setConfig };
