<template>
  <div class="app-view dashboard-view">
    <div class="dashboard-blk">
      <section class="mid-cnt">
        <div class="dashboard-title">
          <h2>Hi {{ dashboard.user ? dashboard.user.name : "user" }}</h2>
        </div>
        <div class="statement-blk">
          <div class="stock-type-panel stock-equity">
            <div class="stock-type-title">
              <span class="icon-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.93 9H13V4.07c3.61.45 6.48 3.32 6.93 6.93zM4 12c0-4.07 3.06-7.44 7-7.93v15.86c-3.94-.49-7-3.86-7-7.93zm9 7.93V13h6.93c-.45 3.61-3.32 6.48-6.93 6.93z"
                  />
                </svg>
              </span>
              <span class="title-head">Equity</span>
            </div>
            <div class="type-blk">
              <div class="margin-lft-box">
                <div class="txt-value">
                  ₹{{
                    dashboard.funds ? dashboard.funds.availabelBalance : "0"
                  }}
                </div>
                <div class="txt-title">Margin Available</div>
              </div>
              <div class="margin-rgt-box">
                <div class="stock-status" :style="{ whiteSpace: 'nowrap' }">
                  Margin Used :
                  <span class="status-value"
                    >₹{{
                      dashboard.funds ? dashboard.funds.utilizedAmount : "0"
                    }}</span
                  >
                </div>

                <div class="stock-status">
                  Opening Balance :
                  <span class="status-value"
                    >₹{{
                      dashboard.funds ? dashboard.funds.sodLimit : "0"
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history-elm-blk">
          <!-- <span class="elm-icon">
              <img
                src="../assets/images/positions.svg"
                width="200"
                height="200"
                alt="history"
              />
            </span> -->
          <RoviCard
            kind="elevated"
            backgroundColor="#DAA71D"
            :edgeColors="{
              bottom: '#624c10',
              right: '#624c10',
            }"
            :customStyle="{
              overflow: 'hidden',
            }"
            fullWidth
            :edgeWidth="6"
          >
            <p>Add money to your Wallet</p>

            <div class="add-wallet-box">
              <input
                type="text"
                v-model="add_money_amount_input"
                class="input_money"
                placeholder="Enter Amount...."
                required
              />
            </div>
            <div class="btn-blk">
              <RoviButton
                kind="elevated"
                showShimmer
                :edgeWidth="4"
                :colorConfig="{
                  borderColor: 'none',
                  backgroundColor: '#000000',
                  color: '#fff',
                  edgeColors: {
                    bottom: '#515151',
                    right: '#515151',
                  },
                }"
                :spacingConfig="{
                  padding: '16px 4em',
                }"
                :textStyle="{
                  fontSize: '1em',
                  fontType: 'caps',
                }"
                :customStyle="{
                  buttonFaceStyles: {
                    overflow: 'hidden',
                  },
                }"
                @click="addMoney"
              >
                Add Money
              </RoviButton>
            </div>
          </RoviCard>
        </div>
      </section>
    </div>
    <loader-comp :isLoading="isLoading" />
  </div>
</template>
<script>
import LoaderComp from "../components/common/LoaderComp.vue";
import { mapState } from "vuex";

export default {
  name: "DashBoard",
  components: {
    "loader-comp": LoaderComp,
  },
  computed: {
    ...mapState([
      "dashboard",
      "watchList",
      "consent_id",
      "isLoggedIn",
      "apiSuccess",
    ]),
  },
  data() {
    return {
      isLoading: false,
      add_money_amount_input: null,
    };
  },
  methods: {
    async addMoney() {
      if (
        this.add_money_amount_input == 0 ||
        this.add_money_amount_input == ""
      ) {
        this.$store.commit("ERR_MSG", "Please enter valid amount");
      } else {
        const { url } = await this.$store.dispatch("addMoney", {
          amount: this.add_money_amount_input,
        });

        try {
          window.JSBridgePlugin.openInBrowser(url);
        } catch (e) {
          window.open(url, "_blank");
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchDashboard");

    if (this.isLoggedIn) {
      this.$router.push(`/login/`);
    }

    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/dashboard.scss";
</style>
