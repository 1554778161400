<template>
  <div class="placer-order-dtl-view">
    <header class="inner-header">
      <div class="lft-head">
        <span
          class="type-action"
          :class="{
            'completed-action': orderDetail.side == 'BUY',
            'buy-action sell-tag': orderDetail.side == 'SELL',
          }"
          >{{ orderDetail.side }}</span
        >
        <span class="instrument-name">{{ orderDetail.symbol }}</span>
        <span class="exchange-type">{{
          orderDetail?.exchange?.split("_")[0]
        }}</span>
      </div>
      <div class="rgt-head">
        <span class="type-action sell-action">{{ orderDetail.status }}</span>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="order-info-blk">
        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Quantity</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.quantity }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Price </span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.price }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Avg. Price</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.price }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Trigger Price</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.trigger_price }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Order Type</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.order_type }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Product</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.product_type }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Validity</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.validity }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Order ID</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.order_id }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Time</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.create_date }}</span>
            </div>
          </div>
        </div>

        <div class="info-data">
          <div class="info-lft">
            <div class="info-title">
              <span class="txt-title">Placed By</span>
            </div>
          </div>
          <div class="info-rgt">
            <div class="info-value">
              <span class="txt-value">{{ orderDetail.user_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <footer class="ftr-bottom">
      <div class="btn-blk">
        <span class="btn-item trade-btn">View Trades</span>
        <span class="btn-item history-btn">View History</span>
      </div>
    </footer> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    orderId: String,
  },
  computed: {
    ...mapState(["orderDetail"]),
  },
  components: {},
  mounted() {
    this.$store.dispatch("fetchOrderDetail", {
      orderId: this.orderId,
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/place-order-detail.scss";

.sell-tag {
  background: rgba(223, 81, 76, 0.1490196078) !important;
  color: #df514c !important;
}

.buy-tag {
  background: rgba(8, 211, 14, 0.1490196078) !important;
  color: #08d30e !important;
}
</style>
