import { createStore } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";

const store = createStore({
  state: {
    apiSuccess: false,
    errorMessage: "",
    successMessage: "",
    dashboard: {},
    openOrders: [],
    executedOrders: [],
    orderBook: [],
    holdingsList: [],
    positionsList: [],
    watchList: [],
    EDIS_DATA: {},
    consent_id: null,
    isLoggedIn: false,
    verify_login_data: null,
    DHAN_TOKEN: null,
    searchList: [],
    isSearchActive: false,
    add_wallet_data: null,
    selected_stock_to_buy: {},
    orderDetail: [],
    fromRoute: null,
  },
  getters: {},
  mutations,
  actions,
  modules: {},
});

export default store;
