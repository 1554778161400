<template>
  <div
    class="symbol-list-item symbol-loss"
    v-if="stock"
    @click="$emit('onSymbolClick')"
  >
    <div class="lft-item">
      <div class="symbol-title">{{ stock.custom_symbol }}</div>
    </div>
    <div class="rgt-item">
      <div
        class="tradingview-widget-container"
        :style="{
          pointerEvents: 'none',
        }"
      >
        <div
          class="tradingview-widget-container__widget"
          style="height: 84px !important"
        ></div>
        <component
          :is="'script'"
          :id="'iframe_id_' + stock.id"
          type="text/javascript"
          src="https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js"
          async
        >
          {{ symbols(stock) }}
        </component>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SymbolList",
  props: ["stock", "ltp", "delta", "id", "page_no"],
  data() {
    return {};
  },
  methods: {
    removeFromWatchList({ id, page_no }) {
      this.$store.dispatch("removeFromWatchList", { id, page_no });
    },
    symbols(stock) {
      return {
        symbol: this.formatGraphName(
          `${stock.exchange}:${stock.trading_symbol.toUpperCase()}`
        ),
        width: "100%",
        colorTheme: "light",
        isTransparent: true,
        locale: "in",
      };
    },
    formatGraphName(name) {
      let graphName = "B" + name.substring(1);
      return graphName.charAt(graphName.length - 1) === "*"
        ? graphName.slice(0, -1)
        : graphName;
    },
  },
};
</script>
<style lang="scss" scoped>
.symbol-list-item {
  width: 100%;
  border-bottom: 1px solid #eee;
  // box-shadow: 0 0px 5px rgb(0 0 0 / 0.2);
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all ease 0.3s;
  background: #fff;

  .lft-item {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 8px 0 0;
    display: flex;
  }
  .rgt-item {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0px;
    display: flex;
    align-items: center;
    transform: scale(0.95);
    justify-content: flex-end;
  }

  .symbol-title {
    color: #daa71d;
    font-size: 0.875em;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 8px;
  }

  .symbol-price-blk {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 0.875em;
    color: #505050;
    align-items: center;
    .symbol-price {
      min-width: 60px;
      text-align: right;
      margin: 0 0 0 4px;
    }

    .symbol-percent {
      width: auto;
      position: relative;
      padding: 0 20px 0 0;
      color: #727272;
      .indicator {
        width: 8px;
        height: 8px;
        border-left: 1px solid #505050;
        border-bottom: 1px solid #505050;
        left: auto;
        top: 0px;
        right: 3px;
        position: absolute;
        transform: rotate(-45deg);
        transition: all ease 0.3s;
      }

      .txt-percent {
        position: relative;
        font-size: 0.75em;
        top: 0px;
        font-weight: 500;
        margin-left: 2px;
      }
    }
  }

  &.symbol-selected {
    background: #f4f6f8;
  }

  &.symbol-profit {
    .symbol-title {
      color: #4caf50;
    }

    .symbol-price-blk {
      .symbol-percent {
        .indicator {
          border-color: #4caf50;
        }
      }

      .symbol-price {
        color: #4caf50;
      }
    }
  }

  &.symbol-loss {
    .symbol-title {
      // color: #df514c;
    }

    .symbol-price-blk {
      .symbol-percent {
        .indicator {
          border-color: #df514c;
          transform: rotate(-225deg);
          top: 5px;
        }
      }

      .symbol-price {
        color: #df514c;
      }
    }
  }
}

.symbol-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px;
  // margin-bottom: 20px;
}

.lft-item {
  display: flex;
  align-items: center;
}

.symbol-title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.rgt-item {
  width: 100%;
  // height: 79px;
  // overflow: hidden;
  padding: 0px;
}

.tradingview-widget-container {
  height: 84px !important;
}

.watch-order-list {
  ul {
    li {
      &:nth-child(even) {
        .symbol-list-item {
          background: #fbfbfb;
        }
      }
    }
  }
}
</style>
