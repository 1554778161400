<template>
  <div class="drop-modal">
    <div class="drop-mdl-cnt">
      <ul>
        <li @click="$emit('openBuyPage')">
          <span class="icon-btn">
            <PlusIcon />
          </span>
          <span class="txt-title">Buy</span>
        </li>

        <li @click="$emit('openSellPage')">
          <span class="icon-btn">
            <MinusIcon />
          </span>
          <span class="txt-title">Sell</span>
        </li>

        <li @click="openMarketDepth">
          <span class="icon-btn">
            <MarketDepthICon />
          </span>
          <span class="txt-title">Market Depth</span>
        </li>

        <li
          @click="$emit('openOrderDetailPage')"
          v-if="selected_stock_to_buy.orderId"
        >
          <span class="icon-btn">
            <InfoIcon />
          </span>
          <span class="txt-title">Info</span>
        </li>

        <li @click="openMarketDepth">
          <span class="icon-btn">
            <TradingIcon />
          </span>
          <span class="txt-title">Chart</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import PlusIcon from "@/components/svgIcons/PlusIcon.vue";
import MinusIcon from "@/components/svgIcons/MinusIcon.vue";
// import RemoveIcon from "@/components/svgIcons/RemoveIcon.vue";
import TradingIcon from "@/components/svgIcons/TradingIcon.vue";
import MarketDepthICon from "@/components/svgIcons/MarketDepthICon.vue";
import InfoIcon from "@/components/svgIcons/InfoIcon.vue";

import { mapState } from "vuex";

export default {
  components: {
    PlusIcon,
    MinusIcon,
    // RemoveIcon,
    TradingIcon,
    MarketDepthICon,
    InfoIcon,
  },
  data() {
    return {};
  },
  props: ["selected_stock_to_buy", "from"],
  computed: {
    ...mapState(["dashboard"]),
  },
  methods: {
    openMarketDepth() {
      this.$router.push({
        name: "MarketDepth",
        props: {
          from: this.from,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.drop-modal {
  .overlay_bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #00000090;
    z-index: 2;
  }

  .drop-mdl-cnt {
    width: 100%;
    max-height: 80%;
    position: fixed;
    top: auto;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    background: #fff;
    overflow-y: auto;

    ul {
      width: 100%;
      height: auto;
      display: block;

      li {
        width: 100%;
        display: flex;
        background: #fff;
        padding: 8px 12px;
        border-bottom: 1px solid #eee;
        align-items: center;
        transition: all ease 0.3s;
        cursor: pointer;

        .icon-btn {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
          flex-grow: 0;
          display: inline-block;
          margin-right: 8px;
          position: relative;
          svg {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            fill: #000;
          }
        }

        .txt-title {
          font-size: 1em;
          color: #000;
        }

        &:hover {
          opacity: 0.8;
          background: #fafafa;
        }

        &:active {
          opacity: 0.8;
          background: #fafafa;
        }
      }
    }
  }
}
</style>
