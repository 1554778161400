<template>
  <form name="frmDIS" method="post" :action="formAction">
    <input type="hidden" name="DPId" :value="dpId" />
    <input type="hidden" name="ReqId" :value="reqId" />
    <input type="hidden" name="Version" :value="version" />
    <input type="hidden" name="TransDtls" :value="transDtls" />
    <input style="display: none" type="submit" value="Submit" ref="submitBtn" />
  </form>
</template>

<script>
export default {
  name: "EdisForm",
  data() {
    return {
      edisHtmlString: null,
      isEdisIframeVisible: false,
    };
  },
  computed: {
    dpId() {
      return this.$route.query.DPId;
    },
    reqId() {
      return this.$route.query.ReqId;
    },
    version() {
      return this.$route.query.Version;
    },
    transDtls() {
      return this.$route.query.TransDtls;
    },
    formAction() {
      return "https://edis.cdslindia.com/eDIS/VerifyDIS/";
    },
  },
  mounted() {
    this.$refs.submitBtn.click();
  },
};
</script>

<style>
.edis-form-iframe {
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 100%;
  background-color: #fff;
}
</style>
