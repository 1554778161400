import { createApp } from "vue";

import VueCookies from "vue3-cookies";
import Toaster from "@meforma/vue-toaster";
import Neopoprovi_vue3 from "neopoprovi_vue3";
import PullRefresh from "pull-refresh-vue3";

import App from "./App.vue";

import router from "./router";
import store from "./store";

const app = createApp(App);

app.provide("theme", "light");

app
  .use(router)
  .use(store)
  .use(VueCookies)
  .use(Toaster)
  .use(PullRefresh)
  .use(Neopoprovi_vue3)
  .mount("#app");
