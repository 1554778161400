<template>
  <div>
    <pull-refresh
      head-height="50"
      loading-text="Loading.."
      loosing-text="Release to refresh.."
      success-text="Updated!"
      pulling-text="Pull down to refresh"
      v-model="isRefreshing"
      @refresh="onRefresh"
    >
      <div class="app-view order-view">
        <div class="order-type-tab">
          <span
            :class="tab.active ? 'tab-item selected-tab' : 'tab-item'"
            v-for="(tab, i) in tabItems"
            :key="i"
            @click="changeActiveTab(tab.id)"
          >
            {{ tab.name }}
          </span>
        </div>

        <section class="mid-cnt" v-if="currentSelectedTabId == 1">
          <not-found-data
            text="You don't have any open orders yet"
            v-if="positions.length == 0"
          />

          <div class="order-place-listing" v-else>
            <h2>Open Orders ({{ positions.length }})</h2>
            <ul>
              <li
                v-for="(order, key) in positions"
                :key="key"
                @click="openActionsModal(order)"
              >
                <div class="order-status">
                  <div class="order-lft-item">
                    <div class="order-tag">
                      <span
                        :class="{ 'buy-tag': order.side == 'LONG' }"
                        class="status-tag"
                        v-if="order.side"
                        >{{ order.side.toUpperCase() }}</span
                      >
                      <span
                        class="status-tag rejected-tag"
                        v-if="order.status"
                        >{{ order.status.toUpperCase() }}</span
                      >
                    </div>
                  </div>
                  <!-- <div class="order-rgt-item">
                <div class="order-time">
                  <span class="time-stamp">{{
                    new Date(order.create_date).toLocaleString()
                  }}</span>
                </div>
              </div> -->
                </div>

                <div class="order-status order-place">
                  <div class="order-lft-item">
                    <div class="info-title">
                      <span class="instrument-title">{{ order.symbol }}</span>
                      <span class="order-type"
                        >{{ order.exchange }} / {{ order.product_type }}</span
                      >
                    </div>
                  </div>

                  <div class="order-rgt-item">
                    <div class="order-qty">
                      <span class="dash-icon"></span>
                      <span class="txt-qty">Qty.</span>
                      <span class="qty-value">{{ order.quantity || "-" }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section class="mid-cnt" v-else-if="currentSelectedTabId == 2">
          <not-found-data
            text="You don't have any orders yet"
            v-if="executedOrders.length == 0"
          />

          <div class="order-place-listing" v-else>
            <h2>Executed Orders ({{ executedOrders.length }})</h2>
            <ul>
              <li
                v-for="(order, key) in executedOrders"
                :key="key"
                @click="openActionsModal(order)"
              >
                <div class="order-status">
                  <div class="order-lft-item">
                    <div class="order-tag">
                      <span class="status-tag buy-tag" v-if="order.side">{{
                        order.side.toUpperCase()
                      }}</span>
                      <span
                        class="status-tag rejected-tag"
                        v-if="order.status"
                        >{{ order.status.toUpperCase() }}</span
                      >
                    </div>
                  </div>
                  <div class="order-rgt-item">
                    <div class="order-time">
                      <span class="time-stamp">{{
                        new Date(order.create_date).toLocaleString()
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="order-status order-place">
                  <div class="order-lft-item">
                    <div class="info-title">
                      <span class="instrument-title">{{ order.symbol }}</span>
                      <span class="order-type"
                        >{{ order.exchange }} / {{ order.product_type }}</span
                      >
                    </div>
                  </div>

                  <div class="order-rgt-item">
                    <div class="order-qty">
                      <span class="dash-icon"></span>
                      <span class="txt-qty">Qty.</span>
                      <span class="qty-value">{{
                        order.quantity || "--"
                      }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="mid-cnt" v-else-if="currentSelectedTabId == 3">
          <not-found-data
            text="You don't have any orders yet"
            v-if="orderBook.length == 0"
          />

          <div class="order-place-listing" v-else>
            <h2>Order History ({{ orderBook.length }})</h2>
            <ul>
              <li
                v-for="(order, key) in orderBook"
                :key="key"
                @click="openActionsModal(order)"
              >
                <div class="order-status">
                  <div class="order-lft-item">
                    <div class="order-tag">
                      <span class="status-tag buy-tag" v-if="order.side">{{
                        order.side.toUpperCase()
                      }}</span>
                      <span
                        class="status-tag rejected-tag"
                        v-if="order.status"
                        >{{ order.status.toUpperCase() }}</span
                      >
                    </div>
                  </div>
                  <div class="order-rgt-item">
                    <div class="order-time">
                      <span class="time-stamp">{{
                        new Date(order.create_date).toLocaleString()
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="order-status order-place">
                  <div class="order-lft-item">
                    <div class="info-title">
                      <span class="instrument-title">{{ order.symbol }}</span>
                      <span class="order-type"
                        >{{ order.exchange }} / {{ order.product_type }}</span
                      >
                    </div>
                  </div>

                  <div class="order-rgt-item">
                    <div class="order-qty">
                      <span class="dash-icon"></span>
                      <span class="txt-qty">Qty.</span>
                      <span class="qty-value"
                        >{{ order.quantity }} / {{ order.quantity }}</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <loader-comp :isLoading="isLoading" />
      </div>
    </pull-refresh>
    <RoviBottomSheet
      @closeBottomSheet="closeActionBottomSheet"
      :isActive="isActionBottomSheet"
    >
      <DropDownModal
        :selected_stock_to_buy="selected_stock_to_buy"
        from="ORDERS"
        @openBuyPage="openBuyBottomSheet"
        @openSellPage="openSellBottomSheet"
        @openOrderDetailPage="openOrderDetailBottomSheet"
      />
    </RoviBottomSheet>

    <RoviBottomSheet
      @closeBottomSheet="() => {}"
      :isActive="isBuySellBottomSheetOpen"
    >
      <RoviCard
        kind="elevated"
        fullWidth
        backgroundColor="#fff"
        :edgeColors="{
          top: '#999',
        }"
        :style="{
          textAlign: 'left',
        }"
        :customStyle="{
          padding: '0px',
        }"
      >
        <BuySell
          :type="transactionType"
          @closeBottomSheet="closeBuySellBottomSheet"
        />
      </RoviCard>
    </RoviBottomSheet>

    <RoviBottomSheet
      @closeBottomSheet="closeOrderDetailBottomSheet"
      :isActive="isOrderDetailBottomSheetOpen"
    >
      <RoviCard
        kind="elevated"
        fullWidth
        backgroundColor="#fff"
        :edgeColors="{
          top: '#999',
        }"
        :style="{
          textAlign: 'left',
        }"
      >
        <OrderDetail
          :orderId="selected_stock_to_buy.orderId"
          @cancelBtnClick="closeBuySellBottomSheet"
          @closeActionBottomSheet="closeActionBottomSheet"
        />
      </RoviCard>
    </RoviBottomSheet>
  </div>
</template>

<script>
import NotFoundData from "@/components/common/NotFoundData.vue";

import LoaderComp from "../components/common/LoaderComp.vue";
import DropDownModal from "@/components/common/DropDownModal.vue";

import BuySell from "@/views/BuySell.vue";
import OrderDetail from "./OrderDetail.vue";

import { mapState } from "vuex";

export default {
  name: "OrderList",
  components: {
    NotFoundData,
    LoaderComp,
    DropDownModal,
    BuySell,
    OrderDetail,
  },
  computed: {
    ...mapState([
      "dashboard",
      "openOrders",
      "executedOrders",
      "orderBook",
      "selected_stock_to_buy",
    ]),
    currentSelectedTabId() {
      return this.tabItems.filter((tab) => tab.active)[0].id;
    },
    positions() {
      return this.orderBook.filter((order) => order.status == "PENDING");
    },
    executed() {
      return this.orderBook.filter((order) => order.status == "TRADED");
    },
  },
  data() {
    return {
      isActionBottomSheet: false,
      isBuySellBottomSheetOpen: false,
      isOrderDetailBottomSheetOpen: false,
      transactionType: "BUY",
      isLoading: false,
      isRefreshing: false,
      tabItems: [
        {
          id: 1,
          name: "Positions",
          active: true,
        },
        {
          id: 2,
          name: "Executed",
          active: false,
        },
        {
          id: 3,
          name: "Order History",
          active: false,
        },
      ],
    };
  },
  methods: {
    openActionsModal(order) {
      this.$store.commit("SET_SELECTED_STOCK_TO_BUY", {
        ...order,
        tradingSymbol: order.symbol,
        graphName: order.exchange.split("_")[0] + ":" + order.symbol,
        securityId: order.securityId,
        exchange: order.exchange.split("_")[0],
        fullName: order.custom_symbol,
        orderId: order.order_id || null,
      });
      this.isActionBottomSheet = true;
    },
    closeActionBottomSheet() {
      this.isActionBottomSheet = false;
    },
    openBuySellBottomSheet() {
      this.isActionBottomSheet = false;
      this.isOrderDetailBottomSheetOpen = false;

      this.isBuySellBottomSheetOpen = true;
    },
    openBuyBottomSheet() {
      this.transactionType = "BUY";
      this.openBuySellBottomSheet();
    },
    openSellBottomSheet() {
      this.transactionType = "SELL";
      this.openBuySellBottomSheet();
    },
    closeBuySellBottomSheet() {
      this.isBuySellBottomSheetOpen = false;
    },
    openOrderDetailBottomSheet() {
      this.isActionBottomSheet = false;
      this.isBuySellBottomSheetOpen = false;

      this.isOrderDetailBottomSheetOpen = true;
    },
    closeOrderDetailBottomSheet() {
      this.isOrderDetailBottomSheetOpen = false;
    },

    changeActiveTab(tabId) {
      this.tabItems.map((tab, i) => {
        this.tabItems[i].active = tab.id == tabId;
      });
    },

    async onRefresh() {
      this.isLoading = true;
      this.isRefreshing = true;
      await this.$store.dispatch("fetchOpenOrders");
      await this.$store.dispatch("fetchExecutedOrders");
      await this.$store.dispatch("fetchOrderBook");
      this.isRefreshing = false;
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchOpenOrders");
    await this.$store.dispatch("fetchExecutedOrders");
    await this.$store.dispatch("fetchOrderBook");
    if (Object.keys(this.dashboard).length === 0) {
      await this.$store.dispatch("fetchDashboard");
    }
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/order.scss";
</style>
