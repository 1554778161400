<template>
  <div class="fullPage"></div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "VerifyDhanLogin",
  computed: {
    ...mapState(["isLoggedIn", "verify_login_data"]),
  },
  async mounted() {
    await this.$store.dispatch("verifyLogin", {
      token: this.$route.query.token,
    });

    if (this.verify_login_data.status == "VERIFIED") {
      this.$router.push({ name: "DashBoard" });
    } else {
      alert(
        "Something went wrong during Login...PLease content admin for more info on this."
      );
    }
  },
};
</script>
<style lang="scss">
.fullPage {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999999;
  overflow: hidden;
}
</style>
