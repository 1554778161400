<template>
  <div class="custom-loader-elm" v-if="isLoading">
    <div class="coin">
      <div class="side heads">
        <img
          class="imageCoin"
          src="@/assets/images/rovi-icon.png"
          width="100"
          height="100"
          alt="Rovi Token"
        />
      </div>
      <div class="side tails">
        <img
          class="imageCoin"
          src="@/assets/images/rovi-icon.png"
          width="100"
          height="100"
          alt="TV91"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isLoading"],
};
</script>
<style>
@import "@/assets/scss/custom-loader.css";
</style>
