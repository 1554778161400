<template>
  <div>
    <pull-refresh
      head-height="50"
      loading-text="Loading.."
      loosing-text="Release to refresh.."
      success-text="Updated!"
      pulling-text="Pull down to refresh"
      v-model="isRefreshing"
      @refresh="onRefresh"
    >
      <div class="app-view holding-view">
        <section class="mid-cnt">
          <div class="order-hold-listing">
            <div class="pos-title-blk">
              <h2>Holdings ({{ holdingsList.length }})</h2>
            </div>
          </div>
          <not-found-data
            v-if="holdingsList.length == 0"
            text="You don't have any holdings yet"
          />

          <div class="order-hold-listing" v-else>
            <ul>
              <li
                v-for="(holding, key) in holdingsList"
                :key="key"
                @click="openActionsModal(holding)"
              >
                <div class="order-status" @click="onStockClick(holding)">
                  <div class="order-lft-item">
                    <div class="order-tag">
                      <div class="pos-qty">
                        <span class="pos-title">Qty.</span>
                        <span class="qty-value">{{
                          holding.availableQty
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-rgt-item">
                    <div class="order-limit">
                      <span class="type-limit"></span>
                      <span
                        class="limit-value"
                        :style="{
                          fontWeight: 500,
                          color: '#727272',
                          fontSize: '0.688em',
                        }"
                      >
                        {{
                          holding.segment == "EQ" ? "EQUITY" : holding.segment
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="order-status order-holding">
                  <div class="order-lft-item">
                    <div class="info-title">
                      <span class="instrument-title">{{
                        holding.tradingSymbol
                      }}</span>
                      <!-- <span class="order-type">{{ holding.exchange }}</span> -->
                    </div>
                  </div>

                  <div class="order-cnt-item">
                    <!-- <div class="instrument-price">{{ holding.avgCostPrice }}</div> -->
                  </div>

                  <div class="order-rgt-item">
                    <div class="order-position-status">
                      <span class="status-percentage gain-status">Avg: </span>
                      <span class="status-val">
                        ₹{{ holding.avgCostPrice }}</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <RoviBottomSheet
          @closeBottomSheet="closeActionBottomSheet"
          :isActive="isActionBottomSheetActive"
        >
          <DropDownModal
            :selected_stock_to_buy="selected_stock_to_buy"
            from="HOLDINGS"
            @openBuyPage="openBuyBottomSheet"
            @openSellPage="openSellBottomSheet"
          />
        </RoviBottomSheet>

        <loader-comp :isLoading="isLoading" />
      </div>
    </pull-refresh>

    <RoviBottomSheet
      @closeBottomSheet="() => {}"
      :isActive="isBuySellBottomSheetOpen"
    >
      <RoviCard
        kind="elevated"
        fullWidth
        backgroundColor="#fff"
        :edgeColors="{
          top: '#999',
        }"
        :style="{
          textAlign: 'left',
        }"
        :customStyle="{
          padding: '0px',
        }"
      >
        <BuySell
          :type="transactionType"
          @cancelBtnClick="closeBuySellBottomSheet"
          @closeActionBottomSheet="closeActionBottomSheet"
        />
      </RoviCard>
    </RoviBottomSheet>
  </div>
</template>
<script>
import DropDownModal from "@/components/common/DropDownModal.vue";
import NotFoundData from "@/components/common/NotFoundData.vue";
import BuySell from "@/views/BuySell.vue";

import LoaderComp from "../components/common/LoaderComp.vue";
import { mapState } from "vuex";

export default {
  name: "HoldingList",
  components: {
    "not-found-data": NotFoundData,
    "loader-comp": LoaderComp,
    DropDownModal,
    BuySell,
  },
  computed: {
    ...mapState(["holdingsList", "selected_stock_to_buy", "dashboard"]),
  },
  data() {
    return {
      isLoading: false,
      isRefreshing: false,
      isActionBottomSheetActive: false,
      isBuySellBottomSheetOpen: false,
      transactionType: "BUY",
      currentClickedStockData: {},
    };
  },
  methods: {
    openActionsModal(holding) {
      this.$store.commit("SET_SELECTED_STOCK_TO_BUY", {
        ...holding,
        tradingSymbol: holding.tradingSymbol,
        graphName: holding["_exchange"] + ":" + holding.tradingSymbol,
        securityId: holding.securityId,
        exchange: holding["_exchange"],
        fullName: holding.tradingSymbol,
        orderId: null,
      });
      this.isActionBottomSheetActive = true;
    },
    closeActionBottomSheet() {
      this.isActionBottomSheetActive = false;
    },
    openBuySellBottomSheet() {
      this.isActionBottomSheet = false;
      this.isBuySellBottomSheetOpen = true;
    },
    closeBuySellBottomSheet() {
      this.isBuySellBottomSheetOpen = false;
    },
    openBuyBottomSheet() {
      this.transactionType = "BUY";
      this.openBuySellBottomSheet();
    },
    openSellBottomSheet() {
      this.transactionType = "SELL";
      this.openBuySellBottomSheet();
    },
    onStockClick(holdingData) {
      this.isDropDownModalOpen = true;
      this.currentClickedStockData = holdingData;
    },
    async onRefresh() {
      this.isLoading = true;
      this.isRefreshing = true;
      await this.$store.dispatch("fetchHoldings");
      this.isRefreshing = false;
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchHoldings");

    if (Object.keys(this.dashboard).length === 0) {
      await this.$store.dispatch("fetchDashboard");
    }

    this.isLoading = false;

    // setTimeout(() => {
    //   this.orderPlaceSuccessAnimation.play();
    // }, 2000);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/holding.scss";
</style>
