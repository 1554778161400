<template>
  <header class="top-header" v-if="!$route.path.includes('/market-depth')">
    <span
      v-for="(tab, i) in headerTabs"
      :key="i"
      @click="$router.push(tab.route)"
      class="nav-tab"
      :class="{
        'active-tab':
          tab.onActiveRoutes.includes($route.path) ||
          $route.path == tab.route ||
          fromRoute == tab.route,
      }"
    >
      <component :is="tab.icon" />
    </span>
    <span class="profile-tab"> {{ profileIcon }} </span>
  </header>
</template>
<script>
import WatchListIcon from "../svgIcons/WatchListIcon.vue";
import DashboardIcon from "../svgIcons/DashboardIcon.vue";
import OrderIcon from "../svgIcons/OrderIcon.vue";
import HoldingIcon from "../svgIcons/HoldingIcon.vue";
import PositionIcon from "../svgIcons/PositionIcon.vue";
import { mapState } from "vuex";

export default {
  name: "TopHeader",
  components: {
    WatchListIcon,
    DashboardIcon,
    OrderIcon,
    HoldingIcon,
    PositionIcon,
  },
  data() {
    return {
      headerTabs: [
        {
          name: "Dashboard",
          route: "/dashboard",
          onActiveRoutes: ["/dashboard", "/"],
          icon: "DashboardIcon",
        },
        {
          name: "Watchlist",
          route: "/watch-list",
          onActiveRoutes: ["/watch-list"],
          icon: "WatchListIcon",
        },
        {
          name: "Position",
          route: "/positions",
          onActiveRoutes: ["/positions"],
          icon: "PositionIcon",
        },
        {
          name: "Holding",
          route: "/holding",
          onActiveRoutes: ["/holding"],
          icon: "HoldingIcon",
        },
        {
          name: "Orders",
          route: "/orders",
          onActiveRoutes: ["/orders"],
          icon: "OrderIcon",
        },
      ],
    };
  },
  computed: {
    ...mapState(["dashboard", "fromRoute"]),
    profileIcon() {
      return this.dashboard.user ? this.dashboard.user.name.charAt(0) : "S";
    },
    prevRoute() {
      return this.$router.options.history.state.back;
    },
  },
};
</script>

<style lang="scss">
.top-header {
  width: 100%;
  display: flex;
  padding: 12px 8px;
  background: #000000;
  // box-shadow: 0 0 5px 2px #00000026;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 11;

  .nav-tab {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    margin: 0 4px;
    border-radius: 1px;
    cursor: pointer;
    svg {
      width: auto;
      height: 28px;
      fill: #ccc;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all ease 0.3s;
    }

    &.active-tab {
      background: #daa71d;
      border: 2px solid #daa71d;
      svg {
        fill: #fff;
        height: 32px;
      }
    }
  }
}

.profile-tab {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  margin: 0 4px 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff3;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  font-size: 1.5em;
  font-weight: 500;
  line-height: normal;
}
</style>
