<template>
  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
  >
    <g><rect fill="none" height="24" width="24" /></g>
    <g>
      <path
        d="M4,7h16v2H4V7z M4,13h16v-2H4V13z M4,17h7v-2H4V17z M4,21h7v-2H4V21z M15.41,18.17L14,16.75l-1.41,1.41L15.41,21L20,16.42 L18.58,15L15.41,18.17z M4,3v2h16V3H4z"
      />
    </g>
  </svg> -->

  <svg
    v-if="filled"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 96 960 960"
    width="24"
  >
    <path
      d="M200 936V296q0-33 23.5-56.5T280 216h400q33 0 56.5 23.5T760 296v640L480 816 200 936Z"
    />
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 96 960 960"
    width="24"
  >
    <path
      d="M200 936V296q0-33 23.5-56.5T280 216h400q33 0 56.5 23.5T760 296v640L480 816 200 936Zm80-122 200-86 200 86V296H280v518Zm0-518h400-400Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>
