export const mutations = {
  API_SUCCESS(state, payload) {
    state.apiSuccess = payload;
  },
  ERR_MSG(state, payload) {
    state.errorMessage = payload;
  },
  SUCCESS_MSG(state, payload) {
    state.successMessage = payload;
  },
  SET_DASHBOARD(state, payload) {
    state.dashboard = payload;
  },
  SET_OPEN_ORDERS(state, payload) {
    state.openOrders = payload;
  },
  SET_EXECUTED_ORDERS(state, payload) {
    state.executedOrders = payload;
  },
  SET_ORDER_BOOK(state, payload) {
    state.orderBook = payload;
  },
  SET_HOLDINGS(state, payload) {
    state.holdingsList = payload;
  },
  SET_POSITIONS(state, payload) {
    state.positionsList = payload;
  },
  SET_WATCHLIST(state, payload) {
    state.watchList = payload;
  },
  SET_EDIS_DATA(state, payload) {
    state.EDIS_DATA = payload;
  },
  SET_CONSENT_ID(state, payload) {
    state.consent_id = payload;
  },
  SET_VERIFY_LOGIN_DATA(state, payload) {
    state.verify_login_data = payload;
  },
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_SEARCH_LIST(state, payload) {
    state.searchList = payload;
  },
  SET_IS_SEARCH_ACTIVE(state, payload) {
    state.isSearchActive = payload;
  },
  SET_ADD_WALLET_DATA(state, payload) {
    state.add_wallet_data = payload;
  },
  SET_SELECTED_STOCK_TO_BUY(state, payload) {
    state.selected_stock_to_buy = payload;
  },
  SET_ORDER_DETAIL(state, payload) {
    state.orderDetail = payload;
  },
  SET_FROM_ROUTE(state, payload) {
    state.fromRoute = payload;
  },
};
