<template>
  <div class="app-view depth-view">
    <Row class="inner-header">
      <span class="icon-btn goback-btn" @click="$router.go(-1)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
          />
        </svg>
      </span>

      <Typography as="span" color="#fff" fontSize="1em" fontWeight="500">{{
        selected_stock_to_buy.fullName
      }}</Typography>
      <RoviTag
        type="warning"
        :colorConfig="{
          background:
            selected_stock_to_buy.exchange == 'BSE' ? '#df514c71' : '#daa81d71',
          color:
            selected_stock_to_buy.exchange == 'BSE' ? '#df514c' : '#daa71d',
          marginLeft: 'auto',
        }"
        :textStyle="{
          fontSize: '0.750em',
          fontWeight: '400',
        }"
        style="padding: 6px 8px"
      >
        {{ selected_stock_to_buy.exchange }}
      </RoviTag>
    </Row>
    <section class="mid-cnt">
      <Column>
        <!-- TradingView Widgets BEGIN -->
        <div class="tradingview-widget-container">
          <div id="tradingview_8d8ea"></div>
          <component
            :is="`script`"
            type="text/javascript"
            src="https://s3.tradingview.com/tv.js"
          />

          <component
            :is="`script`"
            type="text/javascript"
            :style="{
              pointerEvents: 'none',
            }"
            async
            src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js"
          >
            {{ getFundamentalGraphData() }}
          </component>
        </div>
        <!-- TradingView Widgets END -->
      </Column>
    </section>

    <footer class="order-bottom">
      <Row class="grid-elm">
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: '#000',
              color: '#fff',
              edgeColors: {
                top: '#5a5a5a',
                left: '#5a5a5a',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="sellStock"
          >
            Sell
          </RoviButton>
        </div>
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: '#DAA71D',
              color: '#fff',
              edgeColors: {
                top: '#8b6708',
                left: '#8b6708',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="buyStock"
          >
            Buy
          </RoviButton>
        </div>
      </Row>
    </footer>

    <RoviBottomSheet
      @closeBottomSheet="() => {}"
      :isActive="isBuySellBottomSheetOpen"
    >
      <RoviCard
        kind="elevated"
        fullWidth
        backgroundColor="#fff"
        :edgeColors="{
          top: '#999',
        }"
        :style="{
          textAlign: 'left',
        }"
        :customStyle="{
          padding: '0px',
        }"
      >
        <BuySell
          :type="transactionType"
          @cancelBtnClick="closeBuySellBottomSheet"
          @closeActionBottomSheet="closeActionBottomSheet"
        />
      </RoviCard>
    </RoviBottomSheet>
  </div>
</template>
<script>
import BuySell from "./BuySell.vue";

import { mapState } from "vuex";

export default {
  components: {
    BuySell,
  },
  props: ["from"],
  data() {
    return {
      buyMoreOption: false,
      orderItem: 5,
      isBuySellBottomSheetOpen: false,
      transactionType: "BUY",
    };
  },
  computed: {
    ...mapState([
      "watchList",
      "searchList",
      "dashboard",
      "selected_stock_to_buy",
    ]),
    graphName() {
      let graphName = "B" + this.selected_stock_to_buy.graphName.substring(1);
      return graphName.charAt(graphName.length - 1) === "*"
        ? graphName.slice(0, -1)
        : graphName;
    },
  },
  methods: {
    getChartData() {
      return JSON.stringify({
        autosize: true,
        symbol: this.graphName,
        interval: "M",
        timezone: "Asia/Kolkata",
        theme: "dark",
        style: "3",
        locale: "in",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        container_id: "tradingview_8d8ea",
      });
    },
    getFundamentalGraphData() {
      return {
        colorTheme: "light",
        isTransparent: true,
        largeChartUrl: "",
        displayMode: "regular",
        width: "100%",
        height: "100%",
        symbol: this.graphName,
        locale: "in",
      };
    },
    closeBuySellBottomSheet() {
      this.isBuySellBottomSheetOpen = false;
    },
    buyStock() {
      this.transactionType = "BUY";
      this.isBuySellBottomSheetOpen = true;
      // this.$router.push({
      //   name: "BuySell",
      //   params: {
      //     transactionType: "BUY",
      //   },
      // });
    },
    sellStock() {
      this.transactionType = "SELL";
      this.isBuySellBottomSheetOpen = true;

      // this.$router.push({
      //   name: "BuySell",
      //   params: {
      //     transactionType: "SELL",
      //   },
      // });
    },
  },
  async created() {
    if (Object.keys(this.dashboard).length === 0) {
      await this.$store.dispatch("fetchDashboard");
    }
    setTimeout(() => {
      try {
        new window.TradingView.widget({
          autosize: true,
          symbol: this.graphName,
          interval: "M",
          timezone: "Asia/Kolkata",
          theme: "dark",
          style: "3",
          locale: "in",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          container_id: "tradingview_8d8ea",
        });
      } catch (e) {
        console.log(e);
      }
    }, 600);

    this.$store.commit("SET_FROM_ROUTE", this.from);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/market-depth.scss";

.bg-blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 0.7;
}
#tradingview_8d8ea {
  height: 40vh;
}

.tradingview-widget-container {
  height: 100vh !important;
  // margin-bottom: 84px;
  // overflow-y: auto;
  padding: 56px 0 0 0;
}

.grid-elm {
  width: 100%;
  padding: 12px 0px;
  .col-elm {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    padding: 4px;
  }
}
</style>
