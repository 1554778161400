import { BASE_API } from "@/utils/constants";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import { config, setConfig } from "../utils/Helper";

let { cookies } = useCookies();

setConfig({
  headers: {
    "X-Auth-Id": cookies.get("X-Auth-Id"),
    // "dhan-token":
    //   cookies.get("dhan-token") == "false" ? null : cookies.get("dhan-token"),
  },
});

export const actions = {
  async fetchDashboard({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");

    try {
      const { data } = await axios.get(
        `${BASE_API}/dhan/stocks/dashboard/`,
        config
      );

      if (data.status == false) {
        commit("ERR_MSG", data.Message);
        return;
      }

      // cookies.set("dhan-token", data.data.user.dhan_token);

      setConfig({
        headers: {
          "X-Auth-Id": cookies.get("X-Auth-Id"),
          // "dhan-token": data.data.user.dhan_token,
        },
      });

      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_IS_LOGGED_IN", data.data.user.is_login_required);
        if (!data.data.user.is_login_required) {
          commit("SET_DASHBOARD", data.data);
          let watchList = data.data.fav_stocks.map((stock) => {
            return {
              ...stock,
              name: `${stock.exchange}:${stock.trading_symbol.split("-")[0]}`,
            };
          });
          commit("SET_WATCHLIST", watchList);
        }
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchOpenOrders({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/positions/`, config);
      commit("API_SUCCESS", true);
      if (data) {
        console.log(data.data);

        let openOrders = data.data.filter(
          (stock) => stock.custom_symbol !== false
        );
        commit("SET_OPEN_ORDERS", openOrders);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchExecutedOrders({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/trades/`, config);
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_EXECUTED_ORDERS", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchOrderBook({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/orders/`, config);
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_ORDER_BOOK", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchOrderDetail({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/orders/?order_id=${payload.orderId}`,
        config
      );
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_ORDER_DETAIL", data.data[0]);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchHoldings({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/holdings/`, config);
      if (data.status) {
        commit("API_SUCCESS", true);
        let holdings = data.data.filter(
          (stock) => stock._exchange !== undefined
        );
        commit("SET_HOLDINGS", holdings);
        return holdings;
      } else {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.Message || data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchPositions({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/positions/`, config);
      if (data.status) {
        commit("API_SUCCESS", true);
        commit("SET_POSITIONS", data.data);
      } else {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.Message || data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async fetchWatchList({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/dhan/fav/stocks/?page_no=${payload.page_no}`,
        config
      );
      commit("API_SUCCESS", true);
      if (data) {
        let watchList = data.data[0].stocks.map((stock) => {
          return {
            ...stock,
            name: `${stock.exchange}:${stock.trading_symbol.split("-")[0]}`,
            displayName: `${stock.trading_symbol.split("-")[0]}`,
          };
        });
        commit("SET_WATCHLIST", watchList);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async searchStocks({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/search/stocks/?name=${payload.searchText}`,
        config
      );
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_SEARCH_LIST", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async addToWatchList({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/dhan/fav/stocks/`,
        payload,
        config
      );
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      }
      commit("API_SUCCESS", data.result.status);
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async removeFromWatchList({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.put(
        `${BASE_API}/dhan/fav/stocks/`,
        payload,
        config
      );
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      }
      commit("API_SUCCESS", data.result.status);
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async activateEdis({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/activate/edis/`,
        payload,
        config
      );
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      } else {
        commit("API_SUCCESS", data.result.status);
        commit("SET_EDIS_DATA", data.result.data);

        return data.result.data;
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async placeOrder({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/stocks/order/place/`,
        payload,
        config
      );
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);

        return { status: data.result.status, message: data.result.Message };
      } else {
        commit("API_SUCCESS", data.result.status);
        // commit(
        //   "SUCCESS_MSG",
        //   `${data.result.data.side} ${data.result.data.msg}`
        // );
        return { status: data.result.status, message: data.result.data.msg };
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async initiateLogin({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(`${BASE_API}/user/login/`, config);
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_CONSENT_ID", data.data.consentId);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async dhanLogin({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      if (cookies.get("X-Auth-Id") != "") {
        if (process.env.VUE_APP_ENVIRONMENT == "PROD") {
          window.location.href = `https://auth.dhan.co/consent-login?consentId=${payload.consentId}`;
        } else if (process.env.VUE_APP_ENVIRONMENT == "DEV") {
          window.location.href = `https://auth.dhanuat.co/consent-login?consentId=${payload.consentId}`;
        }
      } else {
        commit("ERR_MSG", "No X-Auth Found");
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async verifyLogin({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/user/verified/`,
        payload,
        config
      );
      commit("API_SUCCESS", true);
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      } else {
        commit("API_SUCCESS", data.result.status);
        commit("SET_VERIFY_LOGIN_DATA", data.result.data);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
  async addMoney({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/add/wallet/`,
        payload,
        config
      );
      commit("API_SUCCESS", true);
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      } else {
        commit("API_SUCCESS", data.result.status);
        // window.location.href = data.result.data;
        return {
          url: data.result.data,
        };
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
};
