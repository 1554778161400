<template>
  <Column
    :style="{
      margin: '58px 8px 16px',
    }"
    gap="16px"
  >
    <Row class="v-justify" v-for="(stock, key) in searchList" :key="key">
      <Typography
        as="span"
        fontSize="0.750em"
        color="#000"
        @click="goToSpecificStock(stock)"
        style="display: flex; align-self: center"
      >
        {{ stock.custom_symbol }}
      </Typography>
      <Row class="v-center h-center" gap="6px">
        <Typography as="span" fontSize="0.625em" color="#727272">
          {{ stock.trading_symbol }}
        </Typography>
        <RoviTag
          :colorConfig="{
            backgroundColor: stock.exchange == 'BSE' ? '#DAA71D' : '#df514c',
            color: stock.exchange == 'BSE' ? '#DAA71D' : '#df514c',
          }"
        >
          <Typography
            fontSize="1.1em"
            fontWeight="700"
            :style="{
              letterSpacing: '2px',
            }"
            >{{ stock.exchange }}</Typography
          >
        </RoviTag>
        <addedWatchListIcon
          @click="toggleAddRemoveInWatchlist(stock)"
          :style="{
            height: '18px',
            width: '18px',
          }"
          :filled="checkIfStockExistsInWatchlist(stock)"
        />
      </Row>
    </Row>
  </Column>
</template>
<script>
import { mapState } from "vuex";

import addedWatchListIcon from "../svgIcons/addedWatchListIcon.vue";

export default {
  name: "SearchSymbolListing",
  components: {
    addedWatchListIcon,
  },
  props: ["current_page_no"],
  computed: {
    ...mapState(["searchList", "watchList", "selected_stock_to_buy"]),
  },
  data() {
    return {};
  },
  methods: {
    goToSpecificStock(stock) {
      this.$store.commit("SET_SELECTED_STOCK_TO_BUY", {
        ...stock,
        tradingSymbol: stock.trading_symbol.split("-")[0],
        graphName: `${stock.exchange}:${stock.trading_symbol.split("-")[0]}`,
        securityId: stock.security_id,
        exchange: stock.exchange,
        fullName: stock.custom_symbol,
      });
      this.$router.push(`/market-depth`);
    },
    async toggleAddRemoveInWatchlist(stock) {
      if (this.checkIfStockExistsInWatchlist(stock)) {
        await this.removeFromWatchList(stock);
      } else {
        await this.addToWatchList(stock);
      }
    },
    async addToWatchList(stock) {
      await this.$store.dispatch("addToWatchList", {
        security_id: stock.security_id,
        page_no: this.$props.current_page_no,
      });
      this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
      await this.$store.dispatch("fetchWatchList", {
        page_no: this.$props.current_page_no,
      });
    },
    async removeFromWatchList(stock) {
      this.$store.dispatch("removeFromWatchList", {
        id: stock.id,
        page_no: this.$props.current_page_no,
      });
      this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
      await this.$store.dispatch("fetchWatchList", {
        page_no: this.$props.current_page_no,
      });
    },
    checkIfStockExistsInWatchlist(stock) {
      let temp = false;
      this.watchList.map((watchListStock) => {
        if (watchListStock.security_id == stock.security_id) {
          temp = true;
        }
      }, temp);
      return temp;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-symbol-listing {
  width: 100%;
  height: auto;
  /* position: absolute; */
  left: 0px;
  top: 54px;
  overflow: auto;
  background: #fff;
  z-index: 2;
  ul {
    width: 100%;
    display: block;
    li {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #ddd;
      padding: 12px 16px;
      align-items: center;
      background: #fff;
      position: relative;
      justify-content: space-between;
    }
  }
  addToWatchList .lft-item {
    width: 40%;
    flex-shrink: 0;
    flex-grow: 0;
    padding-right: 8px;
  }

  .rgt-item {
    width: 60%;
    flex-shrink: 0;
    flex-grow: 0;
    padding-left: 8px;
  }

  .symbol-title {
    width: 100%;
    display: block;
    font-size: 0.75em;
    line-height: 1.5;
    color: #505050;
  }
  .exchange-info {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .txt-company {
    font-size: 0.625em;
    color: #727272;
    margin-right: 4px;
  }

  .txt-type {
    min-width: 28px;
    height: auto;
    padding: 4px;
    font-size: 0.625em;
    text-transform: uppercase;
    border-radius: 3px;
    background: #eee;
    text-align: center;

    &.bse-type {
      background: #daa71d26;
      color: #daa71d;
    }

    &.nse-type {
      background: #df514c26;
      color: #df514c;
    }
  }
}
</style>
