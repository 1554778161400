<template>
  <div class="no-found-elm-blk">
    <span class="elm-icon">
      <img
        src="../../assets/images/positions.svg"
        width="200"
        height="200"
        alt="history"
      />
    </span>

    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  props: ["text"],
};
</script>
<style lang="scss" scoped>
.no-found-elm-blk {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 24px 0 16px 0;

  .elm-icon {
    width: 150px;
    height: 150px;
    img {
      width: auto;
      height: 150px;
    }
  }

  p {
    font-size: 1em;
    color: #727272;
    display: block;
    text-align: center;
    line-height: 1.5;
    padding: 0 16px;
  }

  .btn-blk {
    margin-top: 16px;
    justify-content: center;
    .active-stock-btn {
      width: auto;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 12px;
      font-size: 0.875em;
    }
  }
}
</style>
