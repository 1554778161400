<template>
  <Column
    class="h-center v-space-around"
    gap="0"
    :style="{
      height: `calc(100vh - 64px)`,
      margin: '0px',
      padding: '16px 0',
    }"
  >
    <div ref="loginSignupLottieContainer"></div>
    <div class="ftr-bot-fix">
      <div class="log-sign-msg">
        If you are a new user, signup to create account. Existing DHAN user can
        login
      </div>
      <Row class="grid-elm">
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: '#000',
              color: '#fff',
              edgeColors: {
                top: '#5a5a5a',
                left: '#5a5a5a',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="signupClick"
          >
            Signup
          </RoviButton>
        </div>
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: '#DAA71D',
              color: '#fff',
              edgeColors: {
                top: '#8b6708',
                left: '#8b6708',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="loginClick"
          >
            Login
          </RoviButton>
        </div>
      </Row>
    </div>
  </Column>
</template>
<script>
import {
  mainColors,
  colorPalette,
  fontNameSpaces,
} from "neopoprovi_vue3/src/primitives/";

import lottie from "lottie-web";

import { mapState } from "vuex";

export default {
  name: "LogIn",
  data() {
    return {};
  },
  computed: {
    ...mapState(["dashboard", "consent_id", "isLoggedIn", "apiSuccess"]),
    fontNameSpaces() {
      return fontNameSpaces;
    },
    mainColors() {
      return mainColors;
    },
    colorPalette() {
      return colorPalette;
    },
  },
  methods: {
    async loginClick() {
      await this.$store.dispatch("initiateLogin");
      await this.$store.dispatch("dhanLogin", {
        consentId: this.consent_id,
      });
    },
    async signupClick() {
      try {
        window.JSBridgePlugin.openInBrowser(
          `https://invite.dhan.co/?join=ROTE13`
        );
      } catch (e) {
        window.open("https://invite.dhan.co/?join=ROTE13", "_blank");
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchDashboard");

    lottie.loadAnimation({
      container: this.$refs.loginSignupLottieContainer,
      path: "https://assets1.lottiefiles.com/packages/lf20_L7YrbxFm46.json",
      renderer: "svg",
      loop: true,
      autoplay: true,
      name: "login-signup-animation",
    });
  },
};
</script>
<style lang="scss">
@import "../assets/scss/login.scss";
</style>
