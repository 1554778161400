<template>
  <div>
    <TopHeader v-if="!$route.path.includes('/login')" />
    <!-- <transition name="fade" mode="out-in">
      <router-view />
    </transition> -->

    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import TopHeader from "@/components/common/TopHeader.vue";
// import { useCookies } from "vue3-cookies";
// let { cookies } = useCookies();

import { mapState } from "vuex";
export default {
  components: {
    TopHeader,
  },
  computed: {
    ...mapState(["apiSuccess", "errorMessage", "successMessage"]),
  },
  watch: {
    errorMessage() {
      if (this.errorMessage !== "") {
        this.$toast.show(this.errorMessage, {
          type: "error",
          position: "top-right",
          duration: 4000,
        });

        setTimeout(() => {
          this.$store.commit("ERR_MSG", "");
        }, 500);
      }
    },
    successMessage() {
      if (this.apiSuccess == true && this.successMessage !== "") {
        this.$toast.show(this.successMessage, {
          type: "success",
          position: "top-right",
          duration: 4000,
        });
        setTimeout(() => {
          this.$store.commit("SUCCESS_MSG", "");
        }, 500);
      }
    },
  },
  mounted() {
    // if (cookies.get("X-Auth-Id") != "da4fb5c6e93e74d3df8527599fa62642") {
    //   cookies.set("X-Auth-Id", "da4fb5c6e93e74d3df8527599fa62642");
    //   window.location.reload();
    // }
  },
};
</script>
