<template>
  <div class="sellBuy-view">
    <header
      class="header"
      :style="{
        overflow: 'hidden',
      }"
    >
      <!-- :class="transactionType == 'BUY' ? 'header' : 'header sell-color'" -->
      <!-- <div class="top-head">
        <div class="instrument-title">
          <span class="transaction-type">{{ transactionType }}</span>
          <div class="trade-symbol">
            <span class="symbol-title">{{ tradingSymbol }}</span>
            <span class="exchange-type">{{ exchange }}</span>
          </div>
          <span class="cross-icon">X</span>
          <span class="order-qty">{{ quantity }}Qty</span>
        </div>

        <div class="buySell-switch">
          <input
            type="checkbox"
            hidden="hidden"
            id="username"
            @change="changeTransactionType"
          />
          <label class="switch" for="username"></label>
        </div>
      </div> -->

      <!-- <div class="select-option-blk exchange-option">
        <div class="opt-item">
          <label class="methods-list">
            <input
              type="radio"
              name="exchange-segment"
              value="BSE"
              v-model="exchange"
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">BSE:</span>
              <span class="instrument-price">₹--</span>
            </div>
          </label>
        </div>

        <div class="opt-item">
          <label class="methods-list">
            <input
              type="radio"
              name="exchange-segment"
              value="NSE"
              v-model="exchange"
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">NSE:</span>
              <span class="instrument-price">₹--</span>
            </div>
          </label>
        </div>
      </div> -->

      <!-- TradingView Widget BEGIN -->
      <div class="buySell-trading-blk">
        <div class="trading-view-top-parent-container">
          <!-- <component
            :is="`script`"
            src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
          ></component> -->
          <div class="graph_type_box">
            <!-- <lottie-player
              class="player_type"
              src="/lottie-jsons/buy.json"
              background="transparent"
              speed="1"
              loop
              autoplay
              :style="{
                filter:
                  transactionType == 'BUY' ? 'blur(0px)' : 'blur(0px) invert()',
              }"
            /> -->

            <div
              class="player_type"
              ref="tradingBarsLottieContainer"
              :style="{
                filter:
                  transactionType == 'BUY' ? 'blur(0px)' : 'blur(0px) invert()',
              }"
            ></div>
          </div>
          <div class="buySell_inofbox">
            <div class="top-head">
              <div class="instrument-title">
                <span class="transaction-type">{{ transactionType }}</span>
                <div class="trade-symbol">
                  <span class="symbol-title">{{ tradingSymbol }}</span>
                  <span class="exchange-type">{{ exchange }}</span>
                </div>
                <span class="cross-icon">X</span>
                <span class="order-qty">{{ quantity }}Qty</span>
              </div>

              <div class="buySell-switch">
                <input
                  type="checkbox"
                  hidden="hidden"
                  id="transactionType"
                  @change="changeTransactionType"
                />
                <label class="switch" for="transactionType"></label>
              </div>
            </div>
            <div
              :style="{
                pointerEvents: 'none',
                height: '84px',
                padding: '0 40% 0 0',
              }"
            >
              <component
                :is="`script`"
                async
                :tradingSymbol="tradingSymbol"
                :transactionType="transactionType"
                type="text/javascript"
                src="https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js"
              >
                { "symbol": "{{ graphName }}", "width": "100%", "colorTheme":
                "dark", "isTransparent": true, "locale": "in" }
              </component>
            </div>
            <div class="select-option-blk exchange-option">
              <div class="opt-item">
                <label class="methods-list">
                  <input
                    type="radio"
                    name="exchange-segment"
                    value="BSE"
                    v-model="exchange"
                  />
                  <span class="checkmark"></span>
                  <div class="methods-info">
                    <span class="txt-title">BSE</span>
                    <!-- <span class="instrument-price">₹--</span> -->
                  </div>
                </label>
              </div>

              <div class="opt-item">
                <label class="methods-list">
                  <input
                    type="radio"
                    name="exchange-segment"
                    value="NSE"
                    v-model="exchange"
                  />
                  <span class="checkmark"></span>
                  <div class="methods-info">
                    <span class="txt-title">NSE</span>
                    <!-- <span class="instrument-price">₹-/-</span> -->
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TradingView Widget END -->

      <div class="order-type-tab">
        <span
          class="order-item"
          :class="afterMarketOrder ? null : 'selected-order'"
          @click="afterMarketOrder = false"
          >Regular</span
        >
        <span
          class="order-item"
          :class="afterMarketOrder ? 'selected-order' : null"
          @click="afterMarketOrder = true"
          >AMO</span
        >
        <!-- @click="afterMarketOrder = true" -->
        <span
          v-if="this.selected_stock_to_buy.availableQty || this.availableQty"
          class="order-item"
          :style="{
            marginLeft: 'auto',
          }"
        >
          Available Qty:
          {{ this.selected_stock_to_buy.availableQty || this.availableQty }}
        </span>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="select-option-blk market-option">
        <div class="opt-item">
          <label class="methods-list">
            <input
              type="radio"
              name="market-type"
              value="CNC"
              v-model="productType"
              checked
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">CNC</span>
              <!-- <span class="market-type"> MIS</span> -->
            </div>
          </label>
        </div>

        <div class="opt-item">
          <label class="methods-list">
            <input
              type="radio"
              name="market-type"
              value="INTRADAY"
              v-model="productType"
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">INTRADAY</span>
              <!-- <span class="market-type"> CNC</span> -->
            </div>
          </label>
        </div>
        <div class="opt-item">
          <label class="methods-list">
            <input
              type="radio"
              name="market-type"
              value="MARGIN"
              v-model="productType"
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">MARGIN</span>
            </div>
          </label>
        </div>
      </div>

      <div class="input-field-row">
        <label class="txt-label active-label">Qty</label>
        <input
          type="number"
          name=""
          class="custom_input"
          v-model="quantity"
          placeholder=""
        />
      </div>

      <div
        class="input-field-row"
        v-if="orderType == 'LIMIT' || orderType == 'STOP_LOSS'"
      >
        <label class="txt-label active-label"> Price</label>
        <input
          type="number"
          class="custom_input"
          v-model="price"
          placeholder="Enter Price"
        />
      </div>

      <div
        class="input-field-row"
        v-show="orderType == 'STOP_LOSS' || orderType == 'STOP_LOSS_MARKET'"
      >
        <label class="txt-label active-label">Trigger Price</label>
        <input
          type="number"
          class="custom_input"
          v-model="triggerPrice"
          placeholder="Enter Trigger Price"
        />
      </div>

      <div class="select-option-blk select-order-type market-option">
        <div class="opt-item" v-for="(type, i) in orderTypes" :key="i">
          <label class="methods-list">
            <input
              type="radio"
              name="order_type"
              :value="type.value"
              v-model="orderType"
            />
            <span class="checkmark"></span>
            <div class="methods-info">
              <span class="txt-title">{{ type.name }}</span>
            </div>
          </label>
        </div>
      </div>

      <div class="more-option-blk">
        <div class="more-option-tab">
          <div
            class="more-tab"
            :class="{ 'active-more-tab': buyMoreOption }"
            @click="buyMoreOption = !buyMoreOption"
          >
            <span class="txt-title">More options</span>
            <span class="icon-btn more-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="more-tab-content" v-if="buyMoreOption">
          <h2 class="title">Validity</h2>
          <div class="select-option-blk market-option">
            <div class="opt-item">
              <label class="methods-list">
                <input
                  type="radio"
                  name="validity-type"
                  value="DAY"
                  v-model="validity"
                />
                <span class="checkmark"></span>
                <div class="methods-info">
                  <span class="txt-title">Day</span>
                </div>
              </label>
            </div>

            <div class="opt-item">
              <label class="methods-list">
                <input
                  type="radio"
                  name="validity-type"
                  value="IOC"
                  v-model="validity"
                />
                <span class="checkmark"></span>
                <div class="methods-info">
                  <span class="txt-title">IOC </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="order-bottom">
      <!-- <div class="btn-blk">
        <div class="btn-item" @click="goBack">
          <span class="button cancel-btn"> Cancel </span>
        </div>

        <div
          class="btn-item buy-color"
          @click="buyStock"
          v-if="transactionType == 'BUY'"
        >
          <span class="button buy-btn buy-color"> BUY </span>
        </div>

        <div class="btn-item sell-color" @click="sellStock" v-else>
          <span class="button buy-btn sell-color"> SELL </span>
        </div>
      </div> -->

      <Row class="grid-elm">
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: '#e3e3e3',
              color: '#000',
              edgeColors: {
                top: '#000',
                left: '#000',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="goBack"
          >
            Cancel
          </RoviButton>
        </div>
        <div class="col-elm">
          <RoviButton
            kind="flat"
            fullWidth
            :colorConfig="{
              borderColor: 'transparent',
              backgroundColor: transactionType == 'BUY' ? '#DAA71D' : '#000',
              color: '#fff',
              edgeColors: {
                top: transactionType == 'BUY' ? '#8b6708' : '#5a5a5a',
                left: transactionType == 'BUY' ? '#8b6708' : '#5a5a5a',
              },
            }"
            :spacingConfig="{
              padding: '16px',
            }"
            :textStyle="{
              fontSize: '1em',
              fontType: 'caps',
            }"
            @click="transactionType == 'BUY' ? buyStock() : sellStock()"
          >
            {{ transactionType }}
          </RoviButton>
        </div>
      </Row>
    </footer>
    <div
      v-show="isRocketAnimationVisible"
      class="rocket-animation"
      :style="{
        position: 'absolute',
        inset: '0px',
        height: '100%',
        zIndex: '12',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }"
      ref="rocketLottieContainer"
    />
    <div
      :style="{
        position: 'fixed',
        top: '0px',
        left: '0px',
        backgroundColor: '#000000',
        overflow: 'hidden',
        zIndex: '999',
        height: '100vh',
        width: '100%',
      }"
      v-show="isOrderSuccessAnimationActive"
    >
      <div class="order-msg-blk">
        <div
          style="width: 100%"
          class="animate__animated animate__zoomInDown animate__delay-3s"
        >
          <div class="msg-title-blk">
            <!-- Your {{ transactionType }} order for {{ quantity }} Qty of
            {{ selected_stock_to_buy.tradingSymbol }} has been traded
            successfully -->
            Your order placed successfully
          </div>
          <div class="msg-btn-blk">
            <div class="btn-col-item">
              <RoviButton
                kind="elevated"
                fullWidth
                :colorConfig="{
                  borderColor: 'none',
                  backgroundColor: '#DAA71D',
                  color: '#fff',
                  edgeColors: {
                    bottom: '#8b6708',
                    right: '#8b6708',
                  },
                }"
                :spacingConfig="{
                  padding: '16px 12px',
                }"
                :textStyle="{
                  fontSize: '1em',
                  fontType: 'caps',
                }"
                :edgeWidth="4"
                @click="goToPortfolio"
                >Portfolio</RoviButton
              >
            </div>
            <div class="btn-col-item">
              <RoviButton
                kind="elevated"
                fullWidth
                :colorConfig="{
                  borderColor: 'none',
                  backgroundColor: '#fafafa',
                  color: '#6e2c30',
                  edgeColors: {
                    bottom: '#dbdbdb',
                    right: '#dbdbdb',
                  },
                }"
                :spacingConfig="{
                  padding: '16px 12px',
                }"
                :textStyle="{
                  fontSize: '1em',
                  fontType: 'caps',
                }"
                :edgeWidth="4"
                @click="goToHome"
                >Go to Home</RoviButton
              >
            </div>
          </div>
        </div>
      </div>
      <div
        ref="orderSuccessLottieContainer"
        class="order-place-success-animation"
      ></div>
    </div>
    <loader-comp :isLoading="isLoading" />
  </div>
</template>
<script>
import LoaderComp from "../components/common/LoaderComp.vue";
import lottie from "lottie-web";

import { mapState } from "vuex";
export default {
  props: ["type"],
  components: {
    "loader-comp": LoaderComp,
  },
  data() {
    return {
      buyMoreOption: false,
      transactionType: this.$props.type || "BUY",
      tradingSymbol: "",
      exchange: "BSE",
      productType: "CNC",
      quantity: 1,
      price: "",
      triggerPrice: null,
      orderType: "MARKET",
      validity: "DAY",
      afterMarketOrder: false,
      security_id: "",
      isLoading: false,
      availableQty: null,
      isin: null,
      orderTypes: [
        { name: "MARKET", value: "MARKET" },
        { name: "LIMIT", value: "LIMIT" },
        { name: "SL", value: "STOP_LOSS" },
        { name: "SL-M", value: "STOP_LOSS_MARKET" },
      ],
      tradingBarsAnimation: null,
      rocketAnimation: null,
      isRocketAnimationVisible: false,
      orderPlaceSuccessAnimation: null,
      isOrderSuccessAnimationActive: false,
    };
  },
  computed: {
    ...mapState([
      "watchList",
      "dashboard",
      "searchList",
      "holdingsList",
      "selected_stock_to_buy",
      "SET_EDIS_DATA",
    ]),
    graphName() {
      let graphName = "B" + this.selected_stock_to_buy.graphName.substring(1);
      return graphName.charAt(graphName.length - 1) === "*"
        ? graphName.slice(0, -1)
        : graphName;
    },
  },
  async mounted() {
    this.tradingSymbol = this.selected_stock_to_buy.tradingSymbol;
    // this.exchange = this.selected_stock_to_buy.graphName.split(":")[0];
    this.exchange =
      this.selected_stock_to_buy["_exchange"] ||
      this.selected_stock_to_buy["exchange"].split("_")[0];
    this.security_id = this.selected_stock_to_buy.securityId;
    this.fullName = this.selected_stock_to_buy.fullName;

    await this.$store.dispatch("fetchHoldings");

    this.holdingsList.forEach((holding) => {
      if (holding.securityId == this.security_id) {
        this.isin = holding.isin;
        this.availableQty = holding.availableQty;
      }
    });

    // Lottie Trading Bars Animation
    lottie.loadAnimation({
      container: this.$refs.tradingBarsLottieContainer,
      path: "/lottie-jsons/trading-bars.json",
      renderer: "svg",
      loop: true,
      autoplay: true,
      name: "trading-bars-animation",
    });

    // Lottie Rocket Animation
    this.rocketAnimation = lottie.loadAnimation({
      container: this.$refs.rocketLottieContainer,
      path: "/lottie-jsons/rocket.json",
      renderer: "svg",
      loop: false,
      autoplay: false,
      name: "rocket-animation",
    });

    setTimeout(() => {
      const gTags = document
        .querySelector(".rocket-animation")
        .getElementsByTagName("g");

      let counter = 0;
      for (let tagIndex = 0; tagIndex < gTags.length; tagIndex++) {
        let tag = gTags[tagIndex];
        tag.children[0].style.fill =
          tag.children[0].tagName == "path"
            ? counter++
              ? "#000"
              : "#DAA71D"
            : null;
      }
    }, 500);

    // Order Success Page
    this.orderPlaceSuccessAnimation = lottie.loadAnimation({
      container: this.$refs.orderSuccessLottieContainer,
      path: "/lottie-jsons/order-success.json",
      renderer: "svg",
      loop: false,
      autoplay: false,
      name: "order-place-success-animation",
    });

    setTimeout(() => {
      const rectTags = document
        .querySelector(".order-place-success-animation")
        .getElementsByTagName("rect");
      for (let rectIndex = 0; rectIndex < rectTags.length; rectIndex++) {
        let tag = rectTags[rectIndex];
        tag.style.fill = tag.getAttributeNames().includes("fill")
          ? "#000"
          : null;
      }

      const pathTags = document
        .querySelector(".order-place-success-animation")
        .getElementsByTagName("path");

      for (let pathIndex = 0; pathIndex < pathTags.length; pathIndex++) {
        let tag = pathTags[pathIndex];
        tag.style.stroke = tag.getAttributeNames().includes("stroke")
          ? "#DAA71D"
          : null;
        tag.style.fill =
          tag.getAttribute("fill") == "rgb(33,0,87)" ? "#fff" : null;

        tag.style.fill =
          tag.getAttribute("fill") == "rgb(0,255,206)" ? "#e3e3e3" : null;
      }
    }, 2000);

    // this.isOrderSuccessAnimationActive = true;

    this.orderPlaceSuccessAnimation.play();
  },
  watch: {
    async exchange() {
      this.isLoading = true;
      await this.$store.dispatch("searchStocks", {
        searchText: this.tradingSymbol,
      });
      this.searchList.map((stock) => {
        if (
          stock.custom_symbol == this.fullName &&
          stock.exchange == this.exchange
        ) {
          this.security_id = stock.security_id;
        }
      });
      this.isLoading = false;
    },
    orderType() {
      if (this.orderType == "MARKET") {
        this.price = "";
      } else if (this.orderType == "STOP_LOSS_MARKET") {
        this.price = "";
      }
    },
  },
  methods: {
    goBack() {
      this.$emit("cancelBtnClick");
    },
    turnOrderSuccessAnimationOn() {
      this.orderPlaceSuccessAnimation.goToAndStop(0, true);
      document.querySelector(
        ".order-place-success-animation"
      ).children[0].style.height = "102%";
      this.orderPlaceSuccessAnimation.play();
      this.isOrderSuccessAnimationActive = true;
    },
    resetOrderSuccessAnimation() {
      this.orderPlaceSuccessAnimation.pause();
      this.orderPlaceSuccessAnimation.goToAndStop(0, true);
      this.isOrderSuccessAnimationActive = false;
    },
    goToHome() {
      this.$router.push({
        name: "DashBoard",
      });
    },
    goToPortfolio() {
      if (this.$route.path == "/holding") {
        this.$emit("cancelBtnClick");
        this.$emit("closeActionBottomSheet");
        this.resetOrderSuccessAnimation();
      } else {
        this.$router.push({
          name: "HoldingList",
        });
      }
    },
    async buyStock() {
      if (this.orderType == "STOP_LOSS" && this.price < this.triggerPrice) {
        this.$store.commit(
          "ERR_MSG",
          "Price should be greater than Trigger price."
        );
        setTimeout(() => {
          this.$store.commit("ERR_MSG", "");
        }, 1000);
        return;
      }

      let _this = this;

      this.isRocketAnimationVisible = true;
      document.querySelector(".rocket-animation").style.zIndex = "111";
      this.rocketAnimation.goToAndStop(0, true);
      document.querySelector(".rocket-animation").children[0].style.height =
        "150%";
      this.resetOrderSuccessAnimation();
      this.rocketAnimation.play();
      setTimeout(() => {
        _this.rocketAnimation.pause();
      }, 1000);

      const placeOrderResponse = await this.$store.dispatch("placeOrder", {
        dhanClientId: this.dashboard.user.dhan_client_user_id || "",
        exchange: `${this.exchange}_EQ`,
        product_type: this.productType,
        order_type: this.orderType,
        validity: this.validity,
        trading_symbol: this.tradingSymbol,
        quantity: this.quantity || 1,
        price: this.price,
        trigger_price: this.triggerPrice,
        side: this.transactionType,
        security_id: this.security_id,
        disclosedQuantity: "1",
        afterMarketOrder: this.afterMarketOrder,
      });
      if (placeOrderResponse.status == true) {
        // setTimeout(() => {
        _this.rocketAnimation.play();

        setTimeout(() => {
          _this.isRocketAnimationVisible = false;
          _this.turnOrderSuccessAnimationOn();
        }, 2500);
        // }, 2500);
      } else {
        _this.rocketAnimation.play();
        _this.isRocketAnimationVisible = false;
      }
    },
    async activateEDIS({ dhanClientId, security_id }) {
      const EDIS_DATA = await this.$store.dispatch("activateEdis", {
        dhanClientId,
        security_id,
        isin_tpin: this.selected_stock_to_buy.isin || this.isin,
        qty: this.quantity || 1,
        exchange: this.exchange,
        segment: "EQ",
        bulk: false,
      });

      if (EDIS_DATA.status == "SUCCESS") {
        // if (placeOrderResponse == "Validate Qty from CDSL") {
        // }
        // setTimeout(() => {
        // }, 2500);
        // this.$store.commit("SUCCESS_MSG", "Test 2 Order Place Success");
      } else if (EDIS_DATA.status == "FORM_GENERATED") {
        let edisHtmlString = EDIS_DATA.data
          .split(`<body onload="submit()">`)[1]
          .split(`</body>`)[0];

        const parser = new DOMParser();
        const doc = parser.parseFromString(edisHtmlString, "text/html");
        const inputs = doc.querySelectorAll("input");
        const urlParams = new URLSearchParams();

        inputs.forEach((input) => {
          urlParams.append(input.name, input.value);
        });

        const url = `https://dhan.services-money91.com/#/edis/activate?${urlParams.toString()}`;

        try {
          window.JSBridgePlugin.openInBrowser(url);
        } catch (e) {
          window.open(url, "_blank");
        }

        this.rocketAnimation.play();
        this.isRocketAnimationVisible = false;
      } else {
        this.$store.commit("ERR_MSG", EDIS_DATA.Message);
      }

      return EDIS_DATA.status;
    },
    async sellStock() {
      const dhanClientId = this.dashboard.user.dhan_client_user_id || "";
      const exchange = `${this.exchange}_EQ`;
      const product_type = this.productType;
      const order_type = this.orderType;
      const validity = this.validity;
      const trading_symbol = this.tradingSymbol;
      const quantity = this.quantity;
      const price = this.price;
      const trigger_price = this.triggerPrice;
      const side = this.transactionType;
      const security_id = this.security_id;
      const disclosedQuantity = "1";
      const afterMarketOrder = this.afterMarketOrder;

      let _this = this;

      this.isRocketAnimationVisible = true;
      this.rocketAnimation.goToAndStop(0, true);
      document.querySelector(".rocket-animation").children[0].style.height =
        "150%";
      this.resetOrderSuccessAnimation();
      this.rocketAnimation.play();

      setTimeout(() => {
        _this.rocketAnimation.pause();
      }, 1000);

      if (this.selected_stock_to_buy.isin == undefined || this.isin == null) {
        let placeOrderResponse = await this.$store.dispatch("placeOrder", {
          dhanClientId,
          exchange,
          product_type,
          order_type,
          validity,
          trading_symbol,
          quantity,
          price,
          trigger_price,
          side,
          security_id,
          disclosedQuantity,
          afterMarketOrder,
        });
        // setTimeout(() => {
        if (placeOrderResponse.status) {
          _this.rocketAnimation.play();

          setTimeout(() => {
            _this.isRocketAnimationVisible = false;
            _this.turnOrderSuccessAnimationOn();
          }, 2500);
        } else {
          _this.rocketAnimation.play();
          _this.isRocketAnimationVisible = false;
        }

        // }, 2500);
        // this.$store.commit("SUCCESS_MSG", "Test 1 Order Place Success");
      } else {
        let activateEdisResponse = await this.activateEDIS({
          dhanClientId,
          security_id,
        });

        if (activateEdisResponse == "SUCCESS") {
          const placeOrderResponse = await this.$store.dispatch("placeOrder", {
            dhanClientId,
            exchange,
            product_type,
            order_type,
            validity,
            trading_symbol,
            quantity,
            price,
            trigger_price,
            side,
            security_id,
            disclosedQuantity,
            afterMarketOrder,
          });
          if (placeOrderResponse.status) {
            _this.rocketAnimation.play();

            setTimeout(() => {
              _this.isRocketAnimationVisible = false;
              _this.turnOrderSuccessAnimationOn();
            }, 2500);
          } else {
            _this.rocketAnimation.play();
            _this.isRocketAnimationVisible = false;
          }
        }
      }
    },
    changeTransactionType() {
      this.transactionType = this.transactionType == "BUY" ? "SELL" : "BUY";
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/buySell.scss";
.sellBuy-view {
  height: auto;
}
.buy-color {
  background: #daa71d !important;
}
.sell-color {
  background: #ff5722 !important;
}
input[type="checkbox"]:focus,
label:focus {
  outline: 0;
}

.lottie-player {
  filter: blur(2px);
}

.grid-elm {
  width: 100%;
  padding: 12px 0px;
  .col-elm {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    padding: 4px;
  }
}

.buySell_inofbox {
  width: 100%;
  height: auto;
  position: relative;
}

.graph_type_box {
  width: 40%;
  position: absolute;
  left: auto;
  right: -2.333%;
  transform: translateY(-50%);
  top: 60%;
  overflow: hidden;

  .player_type {
    max-width: 100%;
    height: auto;
  }
}

// Order Success Page
.order-msg-blk {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: auto;
  bottom: 10%;
  left: 0;
  z-index: 11111;
  padding: 16px;
  align-items: center;
  .msg-title-blk {
    display: block;
    font-size: 1.25em;
    color: #fff;
    padding: 16px 16px 0;
    text-align: center;
    width: 100%;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: capitalize;
  }

  .msg-btn-blk {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    .btn-col-item {
      width: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0 4px;
    }
  }
}
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}
.animate__animated.animate__delay-2s {
  -webkit-animation-delay: calc(1s * 2);
  animation-delay: calc(1s * 2);
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-3s {
  -webkit-animation-delay: calc(1s * 3);
  animation-delay: calc(1s * 3);
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}
.animate__animated.animate__delay-4s {
  -webkit-animation-delay: calc(1s * 4);
  animation-delay: calc(1s * 4);
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}
.animate__animated.animate__delay-5s {
  -webkit-animation-delay: calc(1s * 5);
  animation-delay: calc(1s * 5);
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
</style>
