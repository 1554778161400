import { createRouter, createWebHashHistory } from "vue-router";

import Login from "../views/Login.vue";
import VerifyDhanLogin from "../views/VerifyDhanLogin.vue";
import WatchList from "../views/WatchList.vue";
import DashBoard from "../views/DashBoard.vue";
import Orders from "../views/Orders.vue";
import OrderDetail from "../views/OrderDetail.vue";
import HoldingList from "../views/HoldingList.vue";
import PositionsList from "../views/PositionsList.vue";
import BuySell from "../views/BuySell.vue";
import EDIS from "../views/EDIS.vue";
import MarketDepth from "../views/MarketDepth.vue";

const routes = [
  {
    path: "/",
    name: "Homepage",
    component: DashBoard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/verify-dhan-login",
    name: "VerifyDhanLogin",
    component: VerifyDhanLogin,
  },
  {
    path: "/watch-list",
    name: "WatchList",
    component: WatchList,
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    component: DashBoard,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/holding",
    name: "HoldingList",
    component: HoldingList,
  },
  {
    path: "/positions",
    name: "PositionsList",
    component: PositionsList,
  },

  {
    path: "/buy-sell/:transactionType",
    name: "BuySell",
    component: BuySell,
  },
  {
    path: "/edis/activate",
    name: "EDIS",
    component: EDIS,
  },
  {
    path: "/market-depth",
    name: "MarketDepth",
    props: (route) => ({ froms: route.redirectedFrom }),
    component: MarketDepth,
  },
  {
    path: "/order-detail",
    name: "OrderDetail",
    component: OrderDetail,
  },

  {
    path: "/setAuth/:xauth",
    name: "setAuth",
    beforeEnter: (to) => {
      document.cookie = `x-auth=${to.params.xauth}`;
      location.reload();
    },
  },
];

const router = createRouter({
  mode: "hash",
  history: createWebHashHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //     if (savedPosition) {
  //         return savedPosition
  //     } else {
  //         return { x: 0, y: 0, behavior: "smooth" }
  //     }
  // }
});

export default router;
