<template>
  <div>
    <pull-refresh
      head-height="50"
      loading-text="Loading.."
      loosing-text="Release to refresh.."
      success-text="Updated!"
      pulling-text="Pull down to refresh"
      v-model="isRefreshing"
      @refresh="onRefresh"
    >
      <div class="app-view position-view">
        <section class="mid-cnt">
          <div class="order-pos-listing">
            <div class="pos-title-blk">
              <h2>Positions ({{ openOrders.length }})</h2>
            </div>
          </div>

          <not-found-data
            text="You don't have any positions yet"
            v-if="openOrders.length == 0"
          />
          <div class="order-pos-listing" v-else>
            <ul>
              <li
                v-for="(position, key) in openOrders"
                :key="key"
                @click="openActionsModal(position)"
              >
                <div class="order-status" @click="onStockClick(position)">
                  <div class="order-lft-item">
                    <div class="order-tag">
                      <span
                        class="status-tag"
                        :class="{
                          'seller-tag':
                            position.side == 'SHORT' || position.side == 'SELL',
                          'buyer-tag':
                            position.side == 'LONG' || position.side == 'BUY',
                        }"
                      >
                        {{
                          position.side == "SHORT" || position.side == "SELL"
                            ? "SELL"
                            : "BUY"
                        }}
                      </span>
                      <span
                        class="status-tag"
                        :style="{
                          textTransform: 'none',
                        }"
                        >{{ position.product_type }}</span
                      >
                      <!-- <div class="pos-qty">
                      <span class="pos-title">Qty.</span>
                      <span class="qty-value">{{ position.quantity }}</span>
                    </div> -->
                    </div>
                  </div>
                  <div class="order-rgt-item">
                    <div
                      class="order-position-status"
                      v-if="position.realized_profit"
                    >
                      <!-- <span
                      class="status-percentage"
                      :class="{
                        'profit-status': position.realized_profit > 0,
                        'loss-status': position.realized_profit < 0,
                      }"
                    >
                      {{ position.realized_profit }}%</span
                    > -->
                      <!-- <span class="status-val">--</span> -->
                    </div>
                  </div>
                </div>

                <div class="order-status order-position">
                  <div class="order-lft-item">
                    <div class="info-title">
                      <span class="instrument-title">{{
                        position.symbol
                      }}</span>
                      <span class="order-type">{{
                        position.exchange.split("_")[0]
                      }}</span>
                    </div>
                  </div>

                  <div class="order-cnt-item">
                    <div class="instrument-price">
                      <!-- ₹ {{
                      (position.buy_avg
                        ? position.side == "LONG"
                          ? parseFloat(position.buy_avg) *
                            parseFloat(position.buy_qty)
                          : parseFloat(position.sell_avg) *
                            parseFloat(position.sell_qty)
                        : position.price
                      ).toFixed(2)
                    }} -->
                    </div>
                  </div>

                  <div class="order-rgt-item">
                    <div class="order-limit">
                      <span class="type-limit">Qty. </span>
                      <span class="limit-value" style="font-size: smaller">
                        {{
                          position.side == "LONG" || position.side == "BUY"
                            ? `${position.buy_qty} x ₹${position.buy_avg} ${
                                position.exchange.split("_")[0]
                              }`
                            : `${position.sell_qty} x ₹${position.sell_avg} ${
                                position.exchange.split("_")[0]
                              }`
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <!-- <div class="total-PL">
          <div class="txt-title">Total P/L</div>
          <div class="txt-value txt-loss">-</div>
        </div> -->
          </div>

          <!-- <div class="order-pos-listing">
        <div class="pos-title-blk">
          <h2>Day's history (2)</h2>
        </div>
        <ul>
          <li v-for="(item, key) in 2" :key="key">
            <div class="order-status">
              <div class="order-lft-item">
                <div class="order-tag">
                  <span class="status-tag cnc-tag">CNC</span>
                  <div class="pos-qty">
                    <span class="pos-title">Qty.</span>
                    <span class="qty-value">16</span>
                  </div>
                </div>
              </div>
              <div class="order-rgt-item">
                <div class="order-limit">
                  <span class="type-limit">LTP</span>
                  <span class="limit-value">32.22</span>
                </div>
              </div>
            </div>

            <div class="order-status order-position">
              <div class="order-lft-item">
                <div class="info-title">
                  <span class="instrument-title">NIFTYBEES</span>
                  <span class="order-type">NSE/CNC</span>
                </div>
              </div>

              <div class="order-cnt-item">
                <div class="instrument-price">₹44.22</div>
              </div>

              <div class="order-rgt-item">
                <div class="order-position-status">
                  <span class="status-val">-390</span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="total-PL">
          <div class="txt-title">Total P/L</div>
          <div class="txt-value txt-profit">+290</div>
        </div>
      </div> -->
        </section>

        <loader-comp :isLoading="isLoading" />
      </div>
    </pull-refresh>
    <RoviBottomSheet
      @closeBottomSheet="closeActionBottomSheet"
      :isActive="isActionBottomSheetActive"
    >
      <DropDownModal
        :selected_stock_to_buy="selected_stock_to_buy"
        from="POSITIONS"
        @openBuyPage="openBuyBottomSheet"
        @openSellPage="openSellBottomSheet"
      />
    </RoviBottomSheet>

    <RoviBottomSheet
      @closeBottomSheet="() => {}"
      :isActive="isBuySellBottomSheetOpen"
    >
      <RoviCard
        kind="elevated"
        fullWidth
        backgroundColor="#fff"
        :edgeColors="{
          top: '#999',
        }"
        :style="{
          textAlign: 'left',
        }"
        :customStyle="{
          padding: '0px',
        }"
      >
        <BuySell
          :type="transactionType"
          @cancelBtnClick="closeBuySellBottomSheet"
          @closeActionBottomSheet="closeActionBottomSheet"
        />
      </RoviCard>
    </RoviBottomSheet>
  </div>
</template>
<script>
import DropDownModal from "@/components/common/DropDownModal.vue";
import NotFoundData from "@/components/common/NotFoundData.vue";
import BuySell from "@/views/BuySell.vue";

import LoaderComp from "../components/common/LoaderComp.vue";
import { mapState } from "vuex";

export default {
  name: "PositionsList",
  components: {
    "not-found-data": NotFoundData,
    "loader-comp": LoaderComp,
    DropDownModal,
    BuySell,
  },
  computed: {
    ...mapState([
      "openOrders",
      "orderBook",
      "selected_stock_to_buy",
      "dashboard",
    ]),
    // positions() {
    //   return this.orderBook.filter((order) => order.status == "PENDING");
    // },
  },
  data() {
    return {
      isLoading: false,
      isRefreshing: false,
      isActionBottomSheetActive: false,
      isBuySellBottomSheetOpen: false,
      transactionType: "BUY",
    };
  },
  methods: {
    openActionsModal(position) {
      this.$store.commit("SET_SELECTED_STOCK_TO_BUY", {
        ...position,
        tradingSymbol: position.symbol,
        graphName: position["exchange"].split("_")[0] + ":" + position.symbol,
        securityId: position.securityId,
        exchange: position["exchange"],
        fullName: position.custom_symbol,
        orderId: position.order_id || null,
      });
      this.isActionBottomSheetActive = true;
    },
    onStockClick(positionData) {
      this.isDropDownModalOpen = true;
      this.currentClickedStockData = positionData;
    },
    async onRefresh() {
      this.isLoading = true;
      this.isRefreshing = true;
      await this.$store.dispatch("fetchHoldings");
      this.isRefreshing = false;
      this.isLoading = false;
    },
    closeActionBottomSheet() {
      this.isActionBottomSheetActive = false;
    },
    openBuySellBottomSheet() {
      // this.isActionBottomSheetActive = false;
      this.isBuySellBottomSheetOpen = true;
    },
    openBuyBottomSheet() {
      this.transactionType = "BUY";
      this.openBuySellBottomSheet();
    },
    openSellBottomSheet() {
      this.transactionType = "SELL";
      this.openBuySellBottomSheet();
    },
    closeBuySellBottomSheet() {
      this.isBuySellBottomSheetOpen = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchOpenOrders");
    if (Object.keys(this.dashboard).length === 0) {
      await this.$store.dispatch("fetchDashboard");
    }
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/positions.scss";

.position-view {
  .order-pos-listing {
    li {
      .order-status {
        .order-tag {
          .status-tag {
            font-weight: 600;
            &.buyer-tag {
              background: #bdffc2;
              color: #0b6601;
            }
          }
        }
      }
    }
  }
}

.seller-tag {
  background: rgba(209, 78, 38, 0.295) !important;
  color: #000 !important;
}
</style>
