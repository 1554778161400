<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
  >
    <path
      d="M0 0h24v24H0V0z"
      fill="none"
    />
    <path d="M19 13H5v-2h14v2z" />
  </svg>
</template>